/* This file contains all the constants for colors and font styles */

$body-font: ETBembo, "Chronicle Text G2", Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
// Note that Gill Sans is the top of the stack and corresponds to what is used in Tufte's books
// However, it is not a free font, so if it is not present on the computer that is viewing the webpage
// The free Google 'Lato' font is used instead. It is similar.
$sans-font: "Gill Sans", "Gill Sans MT", "Gotham Narrow", "Lato", Calibri, sans-serif;
$code-font: Consolas, "Liberation Mono", Menlo, Courier, monospace;
$url-font: "Lucida Console", "Lucida Sans Typewriter", Monaco, "Bitstream Vera Sans Mono", monospace;
$text-color: #222222;
$bg-color: #fffefa;
$contrast-color: #a00000;
// $contrast-color: #111;

$border-color: #bdbdbd66;
$link-style: underline; // choices are 'color' or 'underline'. Color option styles links with $contrast-color set above

$gray: #8d8d8d;
$light-gray: #e6e6e6;
$width: 87.5%;
// $small-width: 92.5%;


body, * {
    margin: 0;
    padding: 0;
    font-size: 16px; /* Converted 1rem to 16px */
}

/* GLOBAL CONTENT STYLING */
strong, em, b, i, a, p, ul,ul li, blockquote {
    font-size: inherit;
}

blockquote {
    border-left: 2px solid $light-gray;
    padding-left: 0.5rem;
    margin: 1.5rem 0;
    color: $gray;
    font-style: italic;
}

ul {
    list-style-type: none;
    margin:  0;
    
    li {
        position: relative;
        margin: 0;
        line-height: 1.6;
        
        &::before {
            content: "• ";
            position: absolute;
            left: -1.25rem;
        }
    }
}

@media (max-width: 768px) {
    // blockquote {
    //     font-size: 1.5rem;
    // }

    // ul,
    // ul li {
    //     font-size: 1.5rem;
    // }

    strong, em, b, i, a, p,div, ul,ul li, blockquote {
        // font-size: 1.75rem !important;
        line-height: 1.7 !important;
    }

    .content {
        width: 100% !important;
        max-width: 100%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin: 0;
    }

    ul {
        margin-left: 2rem;  // Add left margin for the entire list
    }

    ul li::before {
        left: -2rem;  // Increased spacing for mobile
    }

}






/* HEADER STYLING */
.header {
    // height:100%;
    position:absolute;
    left: -110px;
    display:flex;
    flex-direction: column;
}


.header-spacing {
    margin-top: 5px;
}

.header-nav {
    display: none;
}

.header-line {
    position: relative;
    height: calc(100vh - 400px); // Adjust the value (100px) according to your needs
    width: 1px;
    background-color: $text-color;
    margin-left:auto;
    margin-right: auto;
}

.header-icons {
    position: absolute;
    width:30px;
    left: -15px;
    top: 15px;
    display: flex;
    flex-direction: column;
    height: auto;
}

.english-name {
    width: 55px;
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: -5px;
}

.chinese-name {
    width: 55px;
    padding-right: 15px;
    padding-left: 15px;
}


.content {
    max-width: 1020px;
}

.section {
    margin-bottom: 1rem
}

.text {
    position:relative;
    font-family: $body-font;
    color: $text-color;
    width:100%;
    max-width:800px;
    font-size: 19.2px !important; /* Converted 1.2rem to 19.2px */
    margin-top:8px; /* Converted .5rem to 8px */
    margin-bottom: 16px; /* Converted 1rem to 16px */
}

div {
    font-family: $body-font;
    font-size: 1.2rem !important;
}

// .text > * {
//     font-family: $body-font;
//     font-size: 1.6rem;
// }

.flex-row {
    display:flex;
    flex-direction: row;
}


/* BULLETIN STYLING */
.bulletin-bento-item {
    padding: 0px !important;
    min-height: 182px !important;
    max-height: 182px !important;
}

.bulletin-item {
    height: 100%;

    display: flex;
    flex-direction: column;
}

.bulletin-content {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 10px; // Add some padding for the scrollbar
    padding: 15px;
    min-height: 150px;
    max-height: 150px;
}

// .bulletin-text {
//     font-size: 1.1rem;
// }

.hide {
    display: none;
}

#bulletin-toggle {
    position: absolute;
    right: -30px;
    top: 15px;
    display: flex;
    flex-direction: column;
}

.caret-disable {
    position:absolute;
    width:100%;
    height: 100%;
    background-color: $bg-color;
    opacity: .8;
    z-index: 100;
}

.caret-hide {
    display: none;
}

.caret {
    cursor: pointer;
}

/* BENTO */
.bento {
    max-width: 1020px;
    margin-left:auto;
    margin-right:auto;
}

.bento-full {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    grid-auto-rows: minmax(140px, auto);
    margin-bottom: 16px;
}

.bento-item-1-1 {
    grid-column: 1 / 1;
}

.letter {
    position:relative;
    border: 1px solid $border-color;
    padding: 16px;
    border-radius: 8px;
    min-height: 150px;
    background-color: white;
}

.bento-half {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    grid-auto-rows: minmax(140px, auto);
    margin-bottom: 16px;

}

.bento-item-1-2 {
    grid-column: 1 / 2;
}

.bento-item-2-2 {
    grid-column: 2 / 2;
}

.bento-third {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    grid-auto-rows: minmax(140px, auto);
    margin-bottom: 16px;
}

.bento-item-1-3 {
    grid-column: 1 / 3;
}

.bento-item-3-3 {
    grid-column: 3 / 3;
}

.bento-item {
    position:relative;
    border: 1px solid $border-color;
    padding: 16px;
    border-radius: 8px;

    min-height: 150px;
    max-height: 150px;
    background-color: white;
}

.bento-item-no-background {
    position:relative;
    padding: 16px 16px 0px;
    min-height: 0px;
}

.bento-title {
    margin-top: 0px;
    margin-bottom: 8px;
}

.bento-bulletin-header {
    // font-size: 1.5rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding-right: 0px;
    width: fit-content;
    margin-top:auto;
    margin-bottom:auto;
}

.bento-time {
    position: relative;
    // bottom: 6px;
    color: $gray;
    font-size: 16px; /* Converted 1.5rem to 24px */
    margin-top: auto;
    // margin-top: 11px; 
    
    margin-bottom: 1px;
    margin-right: 0px;
    margin-left: auto;
    padding:0px;
    padding-left: 10px
}

.bento-attribution {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 1rem;
    padding: 4px;
    border: 1px solid $border-color;
    border-radius: 4px;
    background-color: $gray;
    color: $bg-color;
    cursor: pointer;
}

.bento-list {
    // font-size: 12px;
    width:calc(100% - 10px);
    // margin:0px;
    margin-left: 10px;
    padding: 0px;
}

.bento-list > li{
    position:relative;
    margin: 0px;
    padding: 0px;
    line-height: 1.3rem
}

.bento-list > li::before{
   content: "• ";
   position:absolute;
    left: -10px;
}   

.bento-signature {
    position: absolute;
    bottom: -7px;
    right: 10px;
    color: $gray
}

/* POST PREVIEW STYLING */

.side-by-side {
    display: flex;
    flex-direction: row;
    
    align-items: center;
    width: auto;
    max-width: 1020px;

    margin-top: 2.5rem;
    margin-bottom: 1rem;

    .post-group {
        width: 50%;
        margin-bottom: auto;
    }

    .post-align {
        padding-left: 10px;
        width: calc(50% - 10px);
    }
}

.post-header {
    // font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 2px;
    padding-right:0px;
    width: 100%;
}

.post-header-subtext{
    color: $gray;
    margin-top:auto;
    margin-bottom: 3px;
    margin-left:10px;
}

.first-section {
    margin-top: 0px !important;
}

.post-section {
    // font-size: 2rem;
    font-style: italic;
    margin-top: 32px;
    font-weight: 500;
    font-size: 22.4px !important
    // text-decoration: underline;
}


.post-month {
    display:flex;
    flex-direction: row;
    font-size: .8rem;
    margin-top: 8px;
    margin-bottom: 8px;
    color: $gray;
}

.post-decoration {
    margin-left: 3px;
    width: 78px;
    height: 1px;
    margin-top:auto;
    margin-bottom: auto;
    border:none;
    background: linear-gradient(90deg, $light-gray 0%, rgba(178, 178, 178, 0.00) 98.33%)
}

.post {
    margin-top:8px;
    margin-bottom:8px;
}

.index-item {
    display: flex;
    flex-direction: row;
    margin-top:8px;
    margin-bottom:8px;

    position:relative;
    font-family: $body-font;
    color: $text-color;
    width:fit-content;
    // max-width:800px;
    // font-size: 1.2rem;
    margin-top:.5rem;
}

.index-link {
    width: fit-content;
    font-size: 19.2px !important; /* Converted 1.6rem to 25.6px */
}

.post-nav {
    display: flex;
    flex-direction: row;
    padding: 8px;
    border: 1px solid $gray;
    border-radius: 4px;
    width: 55%;
}

.post-nav-link {
    width: auto;
    width: 40%
}

.post-nav-hide {
    opacity: 0;

}

.post-trails {
    margin-left:auto;
    margin-right:auto;
    // margin-top: auto;
    margin-bottom: auto;
    // font-size: 1.5rem;
}

.post-trails-up {
    margin-left:auto;
    margin-right:auto;
    width:fit-content;
    // font-size: 1.5rem;
}

.post-large-section {
    font-size: 2rem;
}

.post-large {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 0px;
    width: fit-content;
}

.post-large-header {
    font-size: 1.5rem;

}

.post-large-text {
    font-size: 1rem;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    width: fit-content;
}

#preview-box {
    position: fixed;
    /// interact none
    
    width: calc(100% - 12rem);
    background-color:white;
    padding: 1rem;
    border: 1px solid $border-color;
    border-radius: 4px;
    left: calc(6rem);
    z-index: 1;

    user-select: none;
    display:none;
}

@media (max-width: 1250px) {
    body, * {
        font-size: 19.2px; /* Converted 1.2rem to 19.2px */
    }

    body { 
        padding-left: 3.5% !important;
        padding-right: 3.5% !important;
    }
    
    .header {
        position: relative;
        left: 0px;
        margin-top: 3rem;
        flex-direction: row;
    }

    .chinese-name {
        display: none;
    }

    .header-nav {
        margin-left: 20px;

        margin-top: auto;
        display: flex;
        flex-direction: row;
    }




    #bulletin-toggle {
        display:none;
    }

    .text {
        font-size: 22.4px; /* Converted 1.4rem to 22.4px */
    }
}

@media (max-width: 768px) {
    // body, * {
    //     font-size: 16px; /* Converted 1rem to 16px */
    // }

    .content {
        width: calc(100% - 2rem) !important;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .english-name {
        margin-top: 0px;
    }

    //reversed
    .bento-half {
        display:flex;
        flex-direction: column-reverse;
    }

    .bento-third {
        display:flex;
        flex-direction: column;
    }

    // .text {
    //     font-size: 25.6px !important; /* Converted 1.6rem to 25.6px */
    // }

    // .text > * {
    //     font-family: $body-font;
    //     font-size: 25.6px !important; /* Converted 1.6rem to 25.6px */
    // }

    .index-item {
        font-size: 19.2px; /* Converted 1.2rem to 19.2px */
    }

    // .bento-item {
    //     min-height: 100px;
    //     font-size: 1.4rem;
    // }

    // .bento-time {
    //     margin-bottom: 0px;
    //     font-size: 25.6px !important; /* Converted 1.6rem to 25.6px */
    // }   
    

    .side-by-side {
        display: flex;
        flex-direction: column-reverse;
        
        align-items: flex-start;
        width: auto;
        max-width: 1020px;

        margin-top: 2.5rem;
        margin-bottom: 1rem;
        .post-group {
            width: 100%;
            margin-bottom: auto;
        }

        .post-align {
            margin-top:2rem;
            padding-left: 0px;
            width: 100%;
        }
    }

    // .post-month {
    //     font-size: 1.2rem;
    // }
    
    .post-header-subtext {
        margin-top:auto;
        font-size: 1.2rem;
    }

    #preview-box {
        width: calc(100% - 6rem);
        left: calc(2rem);
    }

    .bento-title, .post-header {
        font-size: 25.6px; /* Converted 1.6rem to 25.6px */
    }
}
